import { createStore } from 'vuex'
import request from '@/network/request'

export default createStore({
  state: {
    keggcompoundpathway: "",
    pathwayko: "",
    pathwaygo: "",
    koreaction: "",
    refresh: "",
    access: "",
    username: "",
    // speciesDict: {
    //   "trae": "Wheat",
    //   "zema": "Maize",
    //   "orsa": "Rice",
    //   "lyes": "Tomato",
    //   "arth": "Arabidopsis",
    //   "glma": "Soybean",
    //   "gohi": "Cotton",
    //   "brna": "Rapeseed",
    //   "pagi": "Ginseng",
    //   "zabu": "Rutaceae",
    // },
    speciesDict: {
      "trae": "Triticum aestivum",
      "zema": "Zea mays",
      "orsa": "Oryza sativa",
      "lyes": "Lycopersicon esculentum",
      "arth": "Arabidopsis thaliana",
      "glma": "Glycine max",
      "gohi": "Gossypium hirsutum",
      "brna": "Brassica napus",
      "pagi": "Panax ginseng",
      "zabu": "Zanthoxylum bungeanum",
    },
    articleUrlDict: {
      "trae": "https://onlinelibrary.wiley.com/doi/10.1111/pbi.13335",
      "zema": "https://www.nature.com/articles/ncomms4438",
      "orsa": "https://www.nature.com/articles/ng.3007",
      "lyes": "https://www.sciencedirect.com/science/article/pii/S009286741731499X?via%3Dihub",
    },
    // frontbaseURL: 'http://47.104.191.145/#/',
    // backendbaseURL: 'http://47.104.191.145/api/',
    // staticURL: 'http://47.104.191.145/static/',
    // demoURL: "http://47.104.191.145/static/",

    // frontbaseURL: 'https://cmhub.org.cn/#/',
    // backendbaseURL: 'https://cmhub.org.cn/api/',
    // staticURL: 'https://cmhub.org.cn/static/',
    // demoURL: "https://cmhub.org.cn/static/",
    // downloadURL: "https://cmhub.org.cn/download/",

    frontbaseURL: 'https://pmhub.org.cn/#/',
    backendbaseURL: 'https://pmhub.org.cn/api/',
    staticURL: 'https://pmhub.org.cn/static/',
    demoURL: "https://pmhub.org.cn/static/",
    downloadURL: "https://pmhub.org.cn/download/",

    // frontbaseURL: 'http://127.0.0.1:8000/#/',
    // frontbaseURL: 'http://localhost:8080/#/',
    // backendbaseURL: 'http://192.168.1.103:8080/api/',
    // backendbaseURL: 'http://localhost:8000/api/',
    // staticURL: "https://cmhub.org.cn/static/",
    // demoURL: "https://cmhub.org.cn/static/",
    // downloadURL: "https://pmhub.org.cn/download/",
    //前后端跨域是单独的后端端口
    // backendbaseURL: 'http://47.104.191.145:8080/',


  },
  mutations: {
    changeKeggcompoundPathway (state, data) {
      state.keggcompoundpathway = data.keggcompoundpathway
      console.log("state.keggcompoundpathway.length")
      console.log(state.keggcompoundpathway.length)
    },
    changePathwayKO (state, data) {
      state.pathwayko = data.pathwayko
      console.log("state.pathwayko.length")
      console.log(state.pathwayko.length)
    },
    changePathwayGO (state, data) {
      state.pathwaygo = data.pathwaygo
      console.log("state.pathwayko.length")
      console.log(state.pathwaygo.length)
    },
    changeKOReaction (state, data) {
      state.koreaction = data.koreaction
      console.log("state.pathwayko.length")
      console.log(state.pathwaygo.length)
    },
    changeRefresh (state, data) {
      state.refresh = data
    },
    changeAccess (state, data) {
      state.access = data
    },
    changeUsername (state, data) {
      state.username = data
    }
  },
  //修改20211009
  actions: {
    getKeggcompoundPathway (context) {
      request({
        url: 'KeggcompoundPathway/list/',
      }).then(res => {
        context.commit({
          type: 'changeKeggcompoundPathway',
          keggcompoundpathway: res
        })
      }).catch(err => {
        console.log(err);
      });
    },
    getPathwayKO (context) {
      request({
        url: 'PathwayKO/list/',
      }).then(res => {
        context.commit({
          type: 'changePathwayKO',
          pathwayko: res
        })
      }).catch(err => {
        console.log(err);
      });
    },
    getPathwayGO (context) {
      request({
        url: 'PathwayGO/list/',
      }).then(res => {
        context.commit({
          type: 'changePathwayGO',
          pathwaygo: res
        })
      }).catch(err => {
        console.log(err);
      });
    },
    getKOReaction (context) {
      request({
        url: 'KOReaction/list/',
      }).then(res => {
        context.commit({
          type: 'changeKOReaction',
          koreaction: res
        })
      }).catch(err => {
        console.log(err);
      });
    },
  },
  modules: {
  }
})
