import axios from 'axios'
import router from '../router'
axios.defaults.withCredentials = true;

export default function request (config) {
  // console.log("config1")
  // console.log(config)
  const instance = axios.create({
    // baseURL: 'http://192.168.1.103:8000/api/',
    // baseURL: 'http://47.104.191.145:80/api/',//服务器ip
    baseURL: 'https://pmhub.org.cn/api/',//服务器ip
    // baseURL: 'https://cmhub.org.cn/api/',//服务器ip
    // baseURL: 'http://47.104.191.145:8080/',//服务器ip
    // baseURL: 'http://localhost:8000/api/',
    // baseURL: 'http://172.21.241.168:80/api/',//服务器ip
    // baseURL: 'http://127.0.0.1:8000/api/',//服务器ip
    timeout: 100000,
    // The value of the 'Access-Control-Allow-Origin' header in the response must not be the wildcard '*'
    withCredentials: false
  })
  instance.interceptors.request.use(config => {
    // console.log("config2")
    // console.log(config);
    return config
  }, err => {
    console.log(err)
  })

  instance.interceptors.response.use(res => {
    // console.log("config3")
    return res.data
  }, err => {
    if (err.response.status == 401) {
      router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.fullPath }
      })
    }
  })
  return instance(config)
}