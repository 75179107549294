<template>
  <div id="app">
    <!-- <router-view/> -->
    <!-- <router-view v-slot="{ Component }">
      <keep-alive include="FeatureBrowse">
        <component :is="Component" />
      </keep-alive>
    </router-view> -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="id" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="id" v-if="!$route.meta.keepAlive" />
    </router-view>

  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {

      }
    },
    components: {

    },
    methods: {
      profile() {
        this.$router.push("/about")
      }
    }
  }
</script>

<style lang="scss">

</style>