import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import ElementPlus from 'element-plus';
// 引入自带主题
// import 'element-plus/lib/theme-chalk/index.css';
// 引入自定义主题
import './style/element-plus/index.css'
import './style/css/cmhub.css'
// import * as K from 'kekule/dist/kekule.min.js';

// console.log(Kekule.VERSION)
let smiles = "C2=Cc1ccccc1C2"
console.log(smiles)
// let mol = K.IO.loadFormatData(smiles, 'smi')
// Kekule.OpenBabel.enable(function(error){
//   if (!error)
//   {
//     let mol = Kekule.IO.loadFormatData('C2=Cc1ccccc1C2', 'smi');
//   }
// })

// let generator = new Kekule.Calculator.ObStructure2DGenerator()
// console.log("smiles")
// generator.setSourceMol(mol)
// generator.executeSync(function() {
//        let newMol = generator.getGeneratedMol();                       
//        console.log(newMol);                     
//     })

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
