<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-row :gutter="24" class="el-row-div">
          <el-col :span="22" class="el-col-div">
            <img src="@/assets/img/logo.svg" @click="HomeView" class="home">
            <el-dropdown class="el-dropdown-div">
              <el-button type="primary">
                Browse<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="MetabolitesBrowse()">Metabolites List</el-dropdown-item>
                  <el-dropdown-item @click="FeaturesBrowse2()">Feature Analysis</el-dropdown-item>
                  <el-dropdown-item @click="FeaturesBrowse()">Metabolites Genetic Analysis</el-dropdown-item>
                  <!-- <el-dropdown-item @click="PathwayBrowse()">Pathways</el-dropdown-item> -->
                  <el-dropdown-item @click="Metabolite2LocSearch()">Metabolite to Locus</el-dropdown-item>
                  <el-dropdown-item @click="LocSearch()">Locu to Metabolites</el-dropdown-item>
                  <!-- <el-dropdown-item>Network</el-dropdown-item> -->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-dropdown class="el-dropdown-div">
              <el-button type="primary">
                Search<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="Structuresearch()">Structure Search</el-dropdown-item>
                  <el-dropdown-item @click="MetabolitesIdentification()">Metabolites Identification</el-dropdown-item>
                  <!-- <el-dropdown-item>Pathway Search</el-dropdown-item> -->
                  <!-- <el-dropdown-item>Gene Search</el-dropdown-item> -->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button class="el-dropdown-div" type="primary" @click="DownloadLibrary()">
              Downloads
            </el-button>
            <el-dropdown class="el-dropdown-div">
              <el-button type="primary">
                About<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- <el-dropdown-item>PMhub</el-dropdown-item> -->
                  <el-dropdown-item @click="Tutorial()">Tutorial</el-dropdown-item>
                  <el-dropdown-item><a id="contact-us" href="mailto: zttian@mail.hzau.edu.cn">Contact us </a>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item>Chen's Lab</el-dropdown-item> -->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
          <el-col :span="2">
            <div>
              <div style="top:20%;position:absolute" v-if='$store.state.username'>
                <div>
                  <router-link :to="{name: 'User'}" class="user">{{$store.state.username}}</router-link>
                </div>
                <a @click="logout" style="cursor:pointer" class="user">
                  logout
                </a>
              </div>
              <div v-else style="top:20%;position:absolute">
                <div>
                  <router-link :to="{name: 'Register'}" class="user">register</router-link>
                </div>
                <div>
                  <router-link :to="{name: 'Login'}" class="user">login</router-link>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-header>
    </el-container>
  </div>
</template>

<script>
  export default {
    name: 'MyheadCompnent',
    data () {
      return {
        show: true,
        username: '',
      }
    },
    methods: {
      MetabolitesBrowse () {
        this.$router.push({ name: 'MetaboliteBrowse' })
      },
      FeaturesBrowse () {
        this.$router.push({ name: 'FeatureBrowse' })
      },
      FeaturesBrowse2 () {
        this.$router.push({ name: 'FeatureBrowse2' })
      },
      HomeView () {
        this.$router.push({ name: 'Home' })
      },
      PathwayBrowse () {
        this.$router.push({ name: 'PathwayBrowse' })
      },
      Metabolite2LocSearch () {
        this.$router.push({ name: 'Metabolite2LocSearch' })
      },
      LocSearch () {
        this.$router.push({ name: 'LocSearch' })
      },
      MetabolitesIdentification () {
        this.$router.push({ name: 'MetaboliteIdentification' })
      },
      Structuresearch () {
        this.$router.push({ name: 'StructureDraw' })
      },
      Tutorial () {
        this.$router.push({ name: 'Tutorial' })
      },
      DownloadLibrary () {
        this.$router.push({ name: 'DownloadLibrary' })
      },
      logout () {
        window.localStorage.setItem('cmhub_access', '')
        window.localStorage.setItem('cmhub_refresh', '')
        window.localStorage.setItem('cmhub_username', '')
        this.$store.commit('changeRefresh', "")
        this.$store.commit('changeAccess', "")
        this.$store.commit("changeUsername", "")
        this.$router.push({ name: 'Login' })
      }
    },
    created () {
      if (!(this.$store.state.username === window.localStorage["cmhub_username"])) {
        if (window.localStorage["cmhub_username"]) {
          this.$store.commit('changeRefresh', window.localStorage["cmhub_refresh"])
          this.$store.commit('changeAccess', window.localStorage["cmhub_access"])
          this.$store.commit("changeUsername", window.localStorage["cmhub_username"])
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-header {
    background-color: #d0e0f5;
    color: #333;
    text-align: left;
    height: 80px;
  }


  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    max-height: 1000px;
  }

  .router-link-active {
    color: #e56b07
  }

  .router-link-exact-active {
    color: #e56b07
  }

  .home {
    cursor: pointer;
    width: 50px;
    height: 40px;
    vertical-align: middle;
    background: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 50px;
    margin-right: 20px;
  }

  .user {
    color: #000000
  }

  .user:hover {
    color: #e56b07
  }

  .el-button {
    /* padding: 5px 2px; */
    /* min-height: 30px; */
    margin-left: 20px;
    margin-right: 20px;
  }

  a {
    text-decoration: none;
  }

  .el-row-div {
    vertical-align: middle;
    line-height: 25px;
  }

  .el-col-div {
    color: red;
    margin-top: 15px;
  }


  .el-dropdown-div {
    vertical-align: middle;
  }

  #contact-us {
    color: #606266
  }

  #contact-us:hover {
    color: #000000
  }
</style>