<template>
  <div class="grid-content bg-purple">
    <div style="margin-top: 15px">
      <el-input placeholder="search items" v-model="searchItem" class="input-with-select" @keyup.enter.native="search"
        size="large">
        <!-- <template #prepend>
          <el-select v-model="selectItem" placeholder="Select">
            <el-option label="PMhub ID" value="metabolite"></el-option>
            <el-option label="Name" value="name"></el-option>
            <el-option label="Smiles" value="smiles"></el-option>
            <el-option label="InChI" value="inchi"></el-option>
          </el-select>
        </template> -->
        <template #prepend>
          <el-select v-model="searchType" placeholder="Select" style="width: 160px">
            <el-option label="Metabolite" value="Metabolite" />
            <el-option label="Feature" value="Feature" />
          </el-select>
        </template>
        <template #append>
          <el-button icon="el-icon-search" @click="search"></el-button>
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MetaboliteBrowseCompnentSearchHome',
    data () {
      return {
        searchItem: '',
        selectItem: "metabolite",
        searchType: "Metabolite",
      }
    },
    methods: {
      search () {
        if (this.searchItem.search(/C\d*H\d*/i) === 0) {
          this.selectItem = 'molecular'
        } else if (this.searchItem.search(/^InChI=/) === 0) {
          this.selectItem = 'inchi'
        } else if (this.searchItem.search(/^MS\d{9}/i) === 0) {
          this.selectItem = 'metabolite'
        } else {
          this.selectItem = 'smiles'
        }
        this.$router.push({ name: 'MetaboliteSearch', params: { selectItem: this.selectItem, searchItem: this.searchItem } })
      }
    },
    props: {
    },
  }
</script>

<style scoped lang="scss">
  .el-select .el-input {
    width: 300px;
  }

  .el-input {
    --el-input-border-radius: 30px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: rgb(229, 107, 7);
  }

  .el-input {
    :deep(.el-input__inner) {
      height: 60px;
      font-size: 24px;
    }

    :deep(.el-input-group__append) {
      color: rgb(229, 107, 7);
    }

    :deep(.el-input__suffix) {
      top: 20%;
    }
  }

  /* .el-input {
    :deep(.el-input-group__append) {
      color: black;
    }
  } */
</style>